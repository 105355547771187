









import { Component, Vue } from "vue-property-decorator";
import Page from "./components/Page.vue";

@Component({
  components: {
    Page
  }
})
export default class App extends Vue {
  visible = false;
  salt = "p@ssw0rd";
  created() {
    const userSalt = new URL(location.href).searchParams.get("salt");
    if (userSalt === this.salt) this.visible = true;
  }
}
