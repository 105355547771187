

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ components: {} })
export default class Pagination extends Vue {
  @Prop({ required: true }) pagination!: any;

  page: number = this.pagination.page;

  @Watch("page")
  onPageChange(newPage: number, oldPage: number) {
    this.$emit("pageChange", newPage);
  }

  get visible() {
    return this.pagination.next || this.pagination.prev;
  }
}
