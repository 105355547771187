









import { Vue, Component, Prop } from "vue-property-decorator";
import { ProductDTO } from "@/model/data.models";
import Product from "@/components/Product.vue";

@Component({
  components: {
    Product
  }
})
export default class ProductList extends Vue {
  @Prop({ default: [] }) list!: ProductDTO[];

  get myList() {
    return this.list;
  }
}
