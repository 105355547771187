
















import { FacetItem } from "@/model/data.models";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class FacetGroup extends Vue {
  @Prop({ default: "Unknown" }) name!: string;
  @Prop({ default: [] }) list!: FacetItem[];

  get visible() {
    return this.list.length;
  }

  onClick(facet: FacetItem) {
    this.$emit("clicked", facet);
  }
}
