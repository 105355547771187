


















import { ProductDTO } from "@/model/data.models";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class Product extends Vue {
  @Prop({ required: true }) product!: ProductDTO;

  get image() {
    return this.product.link_image;
  }

  get name() {
    return this.product.name;
  }

  get description() {
    return this.product.description;
  }

  get link() {
    return this.product.link;
  }

  get price() {
    return this.product.price;
  }
}
