






































import { Component, Vue } from "vue-property-decorator";
import { MeiliSearch } from "meilisearch";
import Search from "@/components/Search.vue";
import Pagination from "@/components/Pagination.vue";
import ProductList from "@/components/ProductList.vue";
import { FacetItem, ProductDTO } from "@/model/data.models";
import FacetGroup from "@/components/FacetGroup.vue";

@Component({
  components: {
    Search,
    Pagination,
    list: ProductList,
    FacetGroup
  }
})
export default class Page extends Vue {
  client!: MeiliSearch;
  productIndex: any;
  facetsDistribution = ["categories"];
  attributesToHighlight = ["name", "description"];
  attributesToCrop = ["description"];
  categoriesFacetDistributions: FacetItem[] = [];
  searchParam: any = {
    limit: 40,
    offset: 0,
    total: 0,
    page: 0,
    next: false,
    prev: false,
    query: "",
    categoriesFacetDistributions: this.categoriesFacetDistributions,
    facetFilters: []
  };
  list: ProductDTO[] = [];

  get total() {
    return this.searchParam.total;
  }

  async onPageChange(page: number) {
    this.clearSearchParam();
    this.searchParam.page = page;
    this.searchParam.offset = page * this.searchParam.limit;
    await this.search();
    this.$nextTick(() => {
      const element: any = this.$refs.top;
      element.scrollIntoView();
    });
  }

  async search() {
    try {
      let facetFilter: any = {};
      if (this.searchParam.facetFilters.length) {
        facetFilter = { facetFilters: this.searchParam.facetFilters };
      }
      const searchResult = await this.productIndex.search(
        this.searchParam.query,
        Object.assign(
          {
            offset: this.searchParam.offset,
            limit: this.searchParam.limit,
            facetsDistribution: this.facetsDistribution,
            attributesToHighlight: this.attributesToHighlight,
            attributesToCrop: this.attributesToCrop
          },
          facetFilter
        )
      );
      this.list = [...searchResult.hits];
      this.searchParam.total = searchResult.nbHits;
      const highestAmount = this.searchParam.offset + this.searchParam.limit;
      this.searchParam.next = highestAmount < this.searchParam.total;
      this.searchParam.prev = this.searchParam.page > 0;
      const categoriesFacetDistributions = this.prepareCategoriesFacetDistribution(
        searchResult.facetsDistribution.categories
      );
      this.categoriesFacetDistributions = categoriesFacetDistributions;
      this.searchParam.categoriesFacetDistributions = categoriesFacetDistributions;
    } catch (err) {
      alert(err.message);
    }
  }

  async onFacetClicked(facet: FacetItem) {
    this.clearSearchParam();
    this.searchParam.facetFilters = [`categories:${facet.name}`];
    await this.search();
  }

  clearSearchParam() {
    this.searchParam.page = 0;
    this.searchParam.offset = 0;
    this.searchParam.next = false;
    this.searchParam.prev = false;
  }

  prepareCategoriesFacetDistribution(list: any) {
    const entries = Object.entries(list);
    let data = entries.map((entry: any) => {
      return {
        name: entry[0],
        amount: entry[1]
      } as FacetItem;
    }) as FacetItem[];

    data = data.filter((facet: FacetItem) => facet.amount > 0);
    console.log(data);
    return data;
  }

  async searchTyped(query: string) {
    this.clearSearchParam();
    this.searchParam.query = query;

    await this.search();
  }

  async created() {
    this.client = new MeiliSearch({
      host: "https://p720.heaven4kids.dk/instantsearch",
      apiKey: ""
    });

    this.productIndex = this.client.index("products");

    this.productIndex.updateSettings({
      rankingRules: [
        "typo",
        "words",
        "proximity",
        "attribute",
        "wordsPosition",
        "exactness"
      ],
      distinctAttribute: null,
      searchableAttributes: [
        "name",
        "description",
        "features",
        "categories",
        "manufacturer_name"
      ],
      displayedAttributes: ["*"],
      stopWords: [],
      synonyms: { lago: ["lego"], sukienka: ["kjole"] },
      attributesForFaceting: ["categories"]
    });
    this.search();
  }
}
