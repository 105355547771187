















import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class Search extends Vue {
  searchValue = "";

  doSearch() {
    this.$emit("changed", this.searchValue);
  }
}
